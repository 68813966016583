import {
  transparentSrc,
  widgetElements,
  ExpandModeMainWidgetEvents as Events,
} from './config/constants';
import model from './model';

function getStringFromNumber(num) {
  if (num < 10) {
    return '0' + num;
  } else {
    return num;
  }
}

export const getSelectorFromName = (name) => {
  return name.includes('#') ? name : `#${name}`;
};
export const connectGalleryStates = ($w, connectedGalleries) => {
  connectedGalleries.forEach((galleryName, index) => {
    const otherGalleriesSelectors = $w(
      getSelectorFromName(galleryName),
    ).onCurrentItemChanged((e) => {
      connectedGalleries
        .filter((name) => name !== galleryName)
        .map(getSelectorFromName)
        .map((gallerySelector) => {
          console.log('e.itemIndex ', e.itemIndex);
          $w(gallerySelector).navigateToIndex(e.itemIndex, 0);
        });
    });
  });
};
const initGalleries = ($w, index, connectedGalleries) => {
  connectedGalleries.forEach((galleryName) => {
    $w(galleryName).navigateToIndex(index, 0);
  });
};

export async function syncButtonStatesToGallery(
  $w,
  itemsLength,
  currentIndex,
  previousSelector,
  nextSelector,
) {
  const isGalleryAbleToNavigateNext = currentIndex < itemsLength - 1;
  const isGalleryAbleToNavigateBack = currentIndex > 0;
  if (isGalleryAbleToNavigateBack) {
    $w(previousSelector).enable();
  } else {
    $w(previousSelector).disable();
  }

  if (isGalleryAbleToNavigateNext) {
    $w(nextSelector).enable();
  } else {
    $w(nextSelector).disable();
  }
}

export default model.createController(({ $w, $bind, $widget, flowAPI }) => {
  $widget.onPropsChanged((oldProps, newProps) => {
    const { items, currentIndex } = newProps.data;
    const itemsLength = items.length;
    const itemsWithBlanks = items.map((item) => {
      return { ...item, src: transparentSrc };
    });
    const alignTextsToIndex = (index) => {
      $w(widgetElements.title).text = items[index]?.title;

      // $w(widgetElements.paragraph).text = items[index].description;
    };
    const currentIndexChangedHandler = (index) => {
      alignTextsToIndex(index);

      syncButtonStatesToGallery(
        $w,
        itemsLength,
        index,
        widgetElements.buttonPrev,
        widgetElements.buttonNext,
      );
      // changeGallery1(index);
    };

    $w(widgetElements.mainGallery).items = items;
    $w(widgetElements.backgroundGallery).items = items;
    $w(widgetElements.navigationGallery).items = itemsWithBlanks;

    $w(widgetElements.counter).text = `${getStringFromNumber(
      currentIndex + 1,
    )} / ${itemsLength}`;

    initGalleries($w, currentIndex, [
      widgetElements.backgroundGallery,
      widgetElements.navigationGallery,
      widgetElements.mainGallery,
    ]);
    // // $w(widgetElements.mainGallery).navigateToIndex(currentIndex, 0);
    currentIndexChangedHandler(currentIndex);
    // // this event will trigger when the index in the gallery changes (by any means)
    $w(widgetElements.mainGallery).onCurrentItemChanged((e) => {
      currentIndexChangedHandler(e.itemIndex);
    });

    // this event will trigger when the gallery initiates a navigation (could be internal(arrows) or externally triggered)
    // @ts-expect-error
    $w(widgetElements.mainGallery).onGalleryNavigationStart((e) => {
      currentIndexChangedHandler(e.currentIndex);
    });
    $w(widgetElements.mainGallery).onCurrentItemChanged((event) => {
      $w(widgetElements.counter).text = `${getStringFromNumber(
        event.itemIndex + 1,
      )} / ${itemsLength}`;
    });
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      // connectGalleryStates($w, [
      //   widgetElements.backgroundGallery,
      //   widgetElements.navigationGallery,
      //   widgetElements.mainGallery,
      // ]);

      $w(widgetElements.buttonPrev).onClick(() => {
        $w(widgetElements.mainGallery).previous();
        $w(widgetElements.backgroundGallery).previous();
      });

      $w(widgetElements.buttonNext).onClick(() => {
        $w(widgetElements.mainGallery).next();
        $w(widgetElements.backgroundGallery).next();
      });

      $w(widgetElements.close).onClick(() => {
        $widget.fireEvent(Events.closeClicked, {});
      });
    },
    exports: {},
  };
});
