import { FastGalleryService } from '../../services/fastGalleryService';
import { convertDraftItemToWixCodeItem } from '../../utils/mediaConverters';
import { getViewMode } from '../../utils/utils';
import { widgetElements } from './config/constants';
import model from './model';

export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  const initGallery = async () => {
    // @ts-expect-error
    const { galleryId } = flowAPI.controllerConfig.config;
    const fastGalleryService = new FastGalleryService({
      viewMode: getViewMode(flowAPI),
      draftGalleryId: galleryId,
      httpClient: flowAPI.httpClient,
    });
    const itemsResponse =
      await fastGalleryService.getGalleryItemsInCurrentContext();

    const wixSDKitems = convertDraftItemToWixCodeItem(itemsResponse);
    // @ts-expect-error
    $w(widgetElements.fastGallery).items = wixSDKitems;

    $w(widgetElements.expandModeWidget).onCloseClicked(() => {
      // @ts-expect-error
      $w(widgetElements.fastGallery).setExpandOpen(false);
    });

    // @ts-expect-error
    $w(widgetElements.fastGallery).onItemClicked((event) => {
      $w(widgetElements.expandModeWidget).data = {
        items: wixSDKitems,
        currentIndex: event.itemIndex,
      };

      // @ts-expect-error
      $w(widgetElements.fastGallery).setExpandOpen(true);
    });
    $w(widgetElements.expandModeWidget).data = {
      items: wixSDKitems,
      currentIndex: 0,
    };
  };

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      initGallery();
    },
    exports: {},
  };
});
